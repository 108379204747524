import React from "react"
import facebook from "../../static/facebookIcon.svg"
import instagram from "../../static/instagramIcon.svg"
import linkedin from "../../static/linkedinIcon.svg"
import greenfo from "../../static/greenfoLogo.svg"

const Footer = ({ data }) => {
  if (!data) return null
  return (
    <footer className="footer">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-6 py-2 col-md-auto">
            <img className="footer-logo" src={greenfo} alt="Greenfo Icon"/>
          </div>
          <div className="col-6 py-2 order-md-2 col-md-auto">
            <div className="footer-social justify-content-end d-flex align-items-center">
              <a href={data.facebookLink} add="true" target="_blank" rel="noreferrer">
                <img src={facebook} alt="facebook Icon"/>
              </a>
              <a href={data.instagramink} add="true" target="_blank" rel="noreferrer">
                <img src={instagram} alt="instagram Icon"/>
              </a>
              <a href={data.linkedinLink} add="true" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="linkedin Icon"/>
              </a>
            </div>
          </div>
          <div className="col-12 py-2 pr-0 ml-md-auto col-md-auto">
            <a className="footer-email" href="mailto:greenfo@greenfo.is">{data.email}</a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
