import React from "react"
import greenfo from "../../static/greenfoLogo.svg"
import { GatsbyImage } from "gatsby-plugin-image"
import scrollTo from 'gatsby-plugin-smoothscroll';

const Header = ({ data }) => {
  return (
    <header className="header">
      <div className="container">
        <nav className="navbar navbar-expand-sm p-0">
          <a className="navbar-brand" href="#"><img src={greenfo} alt="Greenfo Icon"/></a>

          <button className="header-lang btn d-flex align-items-center order-2"><div className="header-text">{data.languageLabel} </div><GatsbyImage image={data.languageFlag.gatsbyImageData} alt={data.languageFlag.description} /></button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item active">
                <a className="nav-link" onClick={() => scrollTo('#news')} role="button">{data.link1}</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" onClick={() => scrollTo('#team')} role="button">{data.link2}</a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </header>
  )
}

export default Header
