import React from "react"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import linkIcon from "../../static/linkIcon.svg"
import { Helmet } from 'react-helmet'
import animation from "../animations/topAnimation.json";
import Lottie from "lottie-react";

const Seo = ({ data, meta, lang }) => {
  if (!data) return null
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      url={`https://www.greenfo.is`}
      image={'https:'+data.image.file.url}
      title={data.title}
      titleTemplate={`%s | Greenfo`}
      meta={[
        {
          name: `keywords`,
          content: data.keywords,
        },
        {
          name: `description`,
          content: data.description,
        },
        {
          property: `og:title`,
          content: `Samey - `+data.title,
        },
        {
          property: `og:description`,
          content: data.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: `bonsai.is`,
        },
        {
          name: `twitter:title`,
          content: data.title,
        },
        {
          name: `twitter:description`,
          content: data.description,
        },
        {
          name: 'og:url',
          content: `https://www.greenfo.is`
        },
        {
          name: 'image',
          property: 'og:image',
          content: 'https:'+data.image.file.url
        },
        {
          name: `author`,
          content: `bonsai.is`
        },
        {
          name: 'google-site-verification',
          content: ''
        }
      ]}
    />
  )
}

const Top = ({ title, subtitle }) => {
  if (!title) return null
  return (
    <div className="py-75 text-dark-green">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 mb-md-0 mb-5">
            <h1>{title}</h1>
            <h3 className="mb-4 pb-4 mw-90">{subtitle}</h3>
            <a href="/" className="btn btn-green">Hafa samband</a>
          </div>
          <div className="col-md-6 img-420 text-center">
          <Lottie animationData={animation} />
          </div>
        </div>
      </div>
    </div>
  )
}

const InfoBlocks = ({ blocks }) => {
  if (!blocks) return null
  return (
    blocks.map((block, index) => {
      return(
        <div key={block.heading} className={(block.backgroundColor==='green' ? "bg-green" : block.backgroundColor==='blue' ? "bg-blue" : "") + " py-75 text-dark-green"}>
        <div className="container">
          <div className="row align-items-center">
            <div className={`col-md-6 mb-md-0 mb-4 order-md-${index}`}>
              <h2>{block.heading}</h2>
              <p>{block.text.text}</p>
            </div>
            <div className="col-md-6 img-400 text-center">
              <GatsbyImage image={block.image.gatsbyImageData} alt={block.image.description} />
            </div>
          </div>
        </div>
        </div>
      )
    })
  )
}

const News = ({ heading, news, label }) => {
  if (!heading || !news) return null
  return (
    <div className="py-160 bg-yellow text-dark-green" id="news">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12">
            <h1>{heading}</h1>
          </div>

          {news.map((article) => {
            return(
              <div className="col-lg-6" key={article.heading}>
                <div className="row flex-sm-nowrap news-card">
                  <div className="col-auto">
                    <div className="news-card-image">
                      <GatsbyImage image={article.image.gatsbyImageData} alt={article.image.description} />
                    </div>
                  </div>
                  <div className="col py-2 d-flex flex-column">
                    <h3>{article.heading}</h3>
                    <p>{article.text}</p>
                    <a href={article.link} add="true" target="_blank" className="btn-link mt-auto text-orange" rel="noreferrer">
                      {label} <img src={linkIcon} alt="link Icon"/>
                    </a>
                  </div>
                </div>
              </div>
            )
          })}          
        </div>
      </div>
    </div>
  )
}

const Team = ({ title, members }) => {
  if (!title || !members) return null
  return (
    <div className="py-75 text-dark-green" id="team">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="mb-2">{title}</h1>
          </div>

          {members.map((member) => {
            return(
              <div className="col-lg-6 team-member" key={member.name}>
                <div className="row">
                  <div className="col-sm-5 mb-sm-0 mb-3">
                    <GatsbyImage image={member.image.gatsbyImageData} alt={member.image.description} />
                  </div>
                  <div className="col-sm-7 d-flex flex-column">
                    <h3 className="font-bold">{member.name}</h3>
                    <a href={member.linkedInLink} className="linkedin" add="true" target="_blank" rel="noreferrer">in</a>
                    <p className="member-title">{member.title}</p>
                    <p>{member.phone}</p>
                    <p>{member.email}</p>
                    <p className="member-about">{member.about}</p>
                    <p className="pt-3 member-footer mt-auto">{member.funLine}</p>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const Sdg = ({ title, blocks}) => {
  if (!title || !blocks) return null
  return (
    <div className="py-120 bg-blue text-dark-green">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <h4>{title}</h4>

            {blocks.map((block) => {
              return(
                <div className="row flex-nowrap sdg-list" key={block.heading}>
                  <div className="col-auto pr-0">
                    <GatsbyImage image={block.illustration.gatsbyImageData} alt={block.illustration.description} />
                  </div>
                  <div className="col d-flex flex-column">
                    <h3 >{block.heading}</h3>
                    <p className="mb-3 mt-auto">{block.text}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

const Thanks = ({ title, logos}) => {
  if (!title || !logos) return null
  return (
    <div className="py-120 text-green text-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-12 text-uppercase pb-3 mb-lg-4 mb-md-3">
            <h4>{title}</h4>
          </div>

          {logos.map((logo) => {
              return(
                <div key={logo.description} className="col-md-3 py-4 bt-logo">
                  <GatsbyImage image={logo.gatsbyImageData} alt={logo.description} />
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

const Home = ({ data }) => {
  const front = data.allContentfulFrontPage.edges[0].node
  const footerData = data.allContentfulFooter.edges[0].node
  const headerData = data.allContentfulHeader.edges[0].node
  const seoData = data.allContentfulSeo.edges[0].node
  if (!front) return null
  return (
    <Layout footerData={footerData} headerData={headerData}>
      <Seo data={seoData}/>
      <Top title={front.title} subtitle={front.subtitle}/>
      <InfoBlocks blocks={front.infoBlocks}/>
      <News heading={front.newsHeading} news={front.news} label={front.newsLabel}/>
      <Team title={front.teamHeading} members={front.teamMembers}/>
      <Sdg title={front.unTitle} blocks={front.unBlocks}/>
      <Thanks title={front.supportHeading} logos={front.supportLogoImages}/>
    </Layout>
  )
}

export const query = graphql`
{
  allContentfulFrontPage {
    edges {
      node {
        unTitle
        unBlocks {
          heading
          illustration {
            gatsbyImageData
            description
          }
          text
        }
        infoBlocks {
          backgroundColor
          heading
          image {
            gatsbyImageData
            description
          }
          text {
            text
          }
        }
        newsHeading
        subtitle
        supportHeading
        supportLogoImages {
          gatsbyImageData
          description
        }
        title
        teamHeading
        teamMembers {
          about
          email
          image {
            gatsbyImageData
            description
          }
          name
          linkedInLink
          phone
          title
          funLine
        }
        newsLabel
        news {
          heading
          text
          link
          image {
            gatsbyImageData
            description
          }
        }
      }
    }
  }
  allContentfulFooter {
    edges {
      node {
        email
        facebookLink
        linkedinLink
        instagramLink
      }
    }
  }
  allContentfulHeader {
    edges {
      node {
        link1
        link2
        languageLabel
        languageFlag {
          gatsbyImageData(
            width: 16
          )
          description
        }
      }
    }
  }
  allContentfulSeo {
    edges {
      node {
        title
        keywords
        image {
          file {
            url
          }
        }
        description
      }
    }
  }
}`

export default Home