import React from "react"
import Header from "./header"
import Footer from "./footer"

const Layout = ({ footerData, headerData, children }) => {
  return (
    <main>
      <Header data={headerData}/>
      {children}
      <Footer data={footerData}/>
    </main>
  )
}

export default Layout
